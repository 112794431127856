/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var count = 0;
        var feed1 = new Instafeed({
          target: 'instafeed1',
          get: 'user',
          userId: '3020529710',
          accessToken: '3020529710.0040604.fe957b6bde9d444e952460c5990fe43e',
          sortBy: 'most-recent',
          limit: '12',
          template: '<a href="{{link}}"><div class="insta-img" style="background-image:url({{image}});"></div></a>',
          resolution: 'standard_resolution',
          success: function() {
            // reset the count every time instafeed.js reloads
            count = 0;
          },
          filter: function(image) {
            // only return true every 3rd image
            return (count++ % 3 === 0) ? true : false;
          }
        });

        var feed2 = new Instafeed({
          target: 'instafeed2',
          get: 'user',
          userId: '3020529710',
          accessToken: '3020529710.0040604.fe957b6bde9d444e952460c5990fe43e',
          sortBy: 'most-recent',
          limit: '12',
          template: '<a href="{{link}}"><div class="insta-img" style="background-image:url({{image}});"></div></a>',
          resolution: 'standard_resolution',
          success: function() {
            // reset the count every time instafeed.js reloads
            count = 0;
          },
          filter: function(image) {
            // only return true every 3rd image
            return (count++ % 3 === 1) ? true : false;
          }
        });

        feed1.run();
        feed2.run();

        $('#btn-midtown-takeout').click(function() {
          fbq('trackCustom', 'OrderTakeout', {location: 'midtown'});
        });

        $('#btn-corunna-takeout').click(function() {
          fbq('trackCustom', 'OrderTakeout', {location: 'corunna'});
        });

        $('#btn-downtown-takeout').click(function() {
          fbq('trackCustom', 'OrderTakeout', {location: 'downtown'});
        });

        $('#btn-skip-delivery').click(function() {
          fbq('trackCustom', 'OrderDelivery', {service: 'skip'});
        });

        $('#btn-uber-delivery').click(function() {
          fbq('trackCustom', 'OrderDelivery', {service: 'uber'});
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
